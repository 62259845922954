//PAGE
import React from 'react';
import PageContent from '../components/layouts/PageContent';
import { FileUploadDetails } from '../components/neighbor-tools/FileUploadDetails';
//COMPONENTS
import { useSearchParams } from 'react-router-dom';

// File Upload Details Page Content
function NeighborTools() {
    // url params
    const [searchParams] = useSearchParams();

    //page content
    return (
        <PageContent
            bodyColor='#fff'
            expanded
        >
            <FileUploadDetails />
        </PageContent>
    );
}

export default NeighborTools;
